/*Colors*/
$color-white: #FFF;
$color-black: #000;

$color-brand-black: #242227;
$color-brand-white: #EBEBEB;
$color-brand-dark-brown: #362A26;
$color-brand-grey: #585859;
$color-brand-brown: #70544F;
$color-brand-tan: #E5DBD2;
$color-brand-red: #8A3A2C;

$color-text: $color-brand-black;

$color-box-shadow: rgba(0,0,0,.11);
