#site-footer {
    .footer-inner {
        background: url('../img/footer-back.jpg') repeat-y;
        background-size: cover;
        text-align: center;
        color: $color-brand-white;
        padding: 55px 0;
    }
    .social-links {
        li {
            padding-right: 100px;
            a {
                font-size: 34px;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
    .footer-links {
        padding: 25px 0 42px 0;
        ul {
            padding-left: 0;
            list-style: none;
            li {
                display: inline-block;
                padding-right: 25px;
                a {
                    @include BentonMedium();
                    font-size: 15px;
                    color: $color-brand-white;
                    letter-spacing: 1.17px;
                    line-height: 46px;
                    text-decoration: none;
                    text-transform: uppercase;
                    &:hover {
                        opacity: .5;
                    }
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
    .copyright {
        @include BentonMedium();
        font-size: 14px;
        color: #362A26;
        padding: 26px 0 13px;
        text-align: center;
        background-color: $color-brand-white;
    }
    @media(max-width: 1025px) {
        .footer-inner {
            background: $color-brand-black;
            padding: 50px 0 45px 0;
            .social-links li {
                padding-right: 45px;
                &:last-child {
                    padding-right: 0;
                }
            }
            .footer-links {
                padding-bottom: 13px;
                ul li {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}
