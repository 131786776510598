#home-page {
    .home-intro-section {
        .intro-wrapper {
            position: relative;
            overflow: hidden;
            .intro-left {
                background-size: cover;
                background-position: bottom;
                height: 900px;
            }
            .intro-right {
                background-size: cover;
                height: 900px;
            }
            .intro-text-container {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                .intro-text {
                    width: 451px;
                    float: right;
                    margin-top: 144px;
                    margin-bottom: 294px;
                    margin-right: 439px;
                    @media(max-width: 2416px) {
                        margin-right: 407px;
                    }
                    @media(max-width: 2333px) {
                        margin-right: 375px;
                    }
                    @media(max-width: 2239px) {
                        margin-right: 344px;
                    }
                    @media(max-width: 2146px) {
                        margin-right: 313px;
                    }
                    @media(max-width: 2052px) {
                        margin-right: 283px;
                    }
                    @media(max-width: 1964px) {
                        margin-right: 254px;
                    }
                    @media(max-width: 1876px) {
                        margin-right: 224px;
                    }
                    @media(max-width: 1786px) {
                        margin-right: 193px;
                    }
                    @media(max-width: 1693px) {
                        margin-right: 163px;
                    }
                    @media(max-width: 1601px) {
                        margin-right: 131px;
                    }
                    @media(max-width: 1513px) {
                        margin-right: 102px;
                    }
                    @media(max-width: 1418px) {
                        margin-right: 71px;
                    }
                    @media(max-width: 1352px) {
                        width: 436px;
                        margin-top: 129px;
                        margin-bottom: 263px;
                        margin-right: 65px;
                    }
                    @media(max-width: 1258px) {
                        width: 419px;
                        margin-top: 114px;
                        margin-bottom: 233px;
                        margin-right: 50px;
                    }
                    @media(max-width: 1164px) {
                        width: 395px;
                        margin-top: 100px;
                        margin-bottom: 200px;
                        margin-right: 43px;
                    }
                    @media(max-width: 1070px) {
                        margin-top: 85px;
                        margin-bottom: 170px;
                        margin-right: 11px;
                    }
                    .text-wrapper {
                        border: 10px solid $color-white;
                        padding: 34px 30px;
                        color: $color-brand-black;
                    }
                    .description,
                    .btn-brown {
                        margin-left: 40px;
                    }
                    .description {
                        margin-top: 27px;
                        margin-bottom: 48px;
                    }
                }
            }
        }
        .intro-wrapper-responsive {
            display: none;
            .intro-top {
                background-color: $color-brand-white;
                text-align: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .intro-bottom {
                background-color: $color-brand-white;
                padding: 18px;
                .text-wrapper {
                    border: 10px solid $color-white;
                    padding: 20px;
                    h1 {
                        font-size: 36px;
                        letter-spacing: 0.56px;
                    }
                    .description {
                        font-size: 16px;
                        letter-spacing: 0.25px;
                        line-height: 21px;
                        margin-bottom: 23px;
                    }
                    .intro-button {
                        text-align: center;
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            .intro-wrapper {
                display: none;
            }
            .intro-wrapper-responsive {
                display: block;
            }
        }
    }
    .collections-section {
        .collections-wrapper {
            overflow: hidden;
            > div {
                height: 320px;
                overflow: hidden;
                z-index: 2;
            }
            .featured-tile {
                position: relative;
                text-align: center;
                .content-wrapper {
                    @include center();
                    .heading {
                        @include BentonMedium();
                        font-size: 32px;
                        color: #EBEBEB;
                        letter-spacing: 0.44px;
                        padding-bottom: 21px;
                    }
                    .description {
                        color: #EBEBEB;
                        padding-bottom: 39px;
                    }
                    .link a {
                        @include TitlingGothic();
                        font-size: 12px;
                        color: #EBEBEB;
                        letter-spacing: 0.75px;
                        text-decoration: none;
                        text-transform: uppercase;
                        &:hover {
                            opacity: .5;
                        }
                    }
                }
            }
            .collection-item {
                position: relative;
                overflow: hidden;
                .item-inner {
                    @include center();
                    border: 5px solid $color-white;
                    padding: 45px 40px;
                    z-index: 5;
                    pointer-events: none;
                    .title {
                        @include BentonMedium();
                        font-size: 21px;
                        color: $color-white;
                        letter-spacing: 0.25px;
                        text-decoration: none;
                        span {
                            font-family: "SSAir";
                            font-size: 18px;
                            color: #EBEBEB;
                            letter-spacing: 0;
                            padding-left: 160px;
                        }
                    }
                    .text {
                        @include BentonRegular();
                        font-size: 14px;
                        color: $color-white;
                        padding-top: 5px;
                    }
                }
                .collection-background {
                    height: 100%;
                    width: 100%;
                    background-color: $color-brand-dark-brown;
                    background-size: cover;
                    transition: all 2s;
                    &:hover {
                        z-index: 1;
                        -ms-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            .collections-wrapper {
                > div {
                    height: 180px;
                }
                .featured-tile {
                    background-color: $color-brand-dark-brown;
                    background-image: none !important;
                    .content-wrapper {
                        width: 100%;
                        .heading {
                            font-size: 23px;
                            letter-spacing: 0.91px;
                            padding-bottom: 10px;
                        }
                        .description {
                            padding-bottom: 21px;
                        }
                    }
                }
                .collection-item {
                    .item-inner {
                        @include center();
                        border: 3px solid $color-white;
                        padding: 20px 21px;
                        .title {
                            span {
                                padding-left: 120px;
                            }
                        }
                    }
                }
            }
        }
    }
    .projects-section {
        .projects-wrapper {
            overflow: hidden;
            > div {
                height: 320px;
                z-index: 2;
                overflow: hidden;
            }
            .featured-tile {
                float: right;
                position: relative;
                text-align: center;
                background-color: $color-brand-dark-brown;
                .content-wrapper {
                    @include center();
                    .heading {
                        @include BentonMedium();
                        font-size: 32px;
                        color: #EBEBEB;
                        letter-spacing: 0.44px;
                        padding-bottom: 21px;
                    }
                    .description {
                        color: #EBEBEB;
                        padding-bottom: 39px;
                    }
                    .link a {
                        @include TitlingGothic();
                        font-size: 12px;
                        color: #EBEBEB;
                        letter-spacing: 0.75px;
                        text-decoration: none;
                        text-transform: uppercase;
                        &:hover {
                            opacity: .5;
                        }
                    }
                }
            }
            .project-item {
                position: relative;
                &.first-item {
                    height: 640px;
                }
                .item-inner {
                    pointer-events: none;
                    z-index: 5;
                    position: absolute;
                    right: 30px;
                    bottom: 30px;
                    @include BentonMedium();
                    font-size: 14px;
                    letter-spacing: 0.17px;
                    background-color: #EEEAE4;
                    padding: 16px 14px;
                    .title {
                        color: $color-brand-black;
                        .ss-picture {
                            font-family: "SSStandard";
                            padding: 0 7px 0 17px;
                        }
                        span {
                            color: #71544E;
                        }
                    }
                }
                .project-background {
                    height: 100%;
                    width: 100%;
                    background-color: $color-brand-dark-brown;
                    background-size: cover;
                    background-position: center;
                    transition: all 2s;
                    &:hover {
                        z-index: 1;
                        -ms-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            .projects-wrapper {
                > div {
                    height: 500px;
                    background-color: $color-brand-black;
                }
                .featured-tile {
                    height: 160px;
                    float: none;
                    background-image: none !important;
                    .content-wrapper {
                        width: 80%;
                        .heading {
                            font-size: 23px;
                            letter-spacing: 0.91px;
                            padding-bottom: 10px;
                        }
                        .description {
                            padding-bottom: 21px;
                        }
                    }
                }
                .project-item {
                    background-position: center;
                    &.first-item {
                        height: 500px;
                    }
                }
            }
        }
        @media(max-width: 700px) {
            .projects-wrapper {
                > div {
                    height: 320px;
                    background-color: $color-brand-black;
                }
                .project-item {
                    background-position: center;
                    &.first-item {
                        height: 320px;
                    }
                }
            }
        }
    }
    .post-section {
        background: $color-white;
        .post-left {
            position: relative;
            padding: 80px 0 80px 80px;
            .tile-heading {
                padding-bottom: 38px;
                h2 {
                    color: $color-brand-black;
                    letter-spacing: 0.44px;
                    margin-bottom: 8px;
                }
                a {
                    @include TitlingGothic();
                    font-size: 12px;
                    letter-spacing: 0.75px;
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
            .post-text {
                position: absolute;
                width: 94%;
                z-index: 1;
                .text-wrapper {
                    border: 10px solid $color-brand-white;
                    padding: 34px 30px;
                    @include BentonMedium();
                    div {
                        max-width: 60%;
                    }
                    .title {
                        font-size: 26px;
                        letter-spacing: 0.43px;
                        line-height: 36px;
                        padding-bottom: 29px;
                    }
                    .date {
                        font-size: 14px;
                        color: $color-brand-brown;
                        letter-spacing: 1.17px;
                        text-transform: uppercase;
                        padding-bottom: 5px;
                    }
                    .summary {
                        @include BentonRegular();
                        font-size: 16px;
                        letter-spacing: 0.25px;
                        line-height: 26px;
                        padding-bottom: 21px;
                    }
                    .link a {
                        @include TitlingGothic();
                        font-size: 12px;
                        letter-spacing: 0.75px;
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .post-right {
            background-size: cover;
        }
        .responsive-container {
            display: none;
            .responsive-post-top {
                text-align: center;
                padding: 30px 0;
                h2 {
                    font-size: 23px;
                    letter-spacing: 0.91px;
                    margin: 0 0 6px 0;
                }
                a {
                    @include TitlingGothic();
                    font-size: 12px;
                    letter-spacing: 0.75px;
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
            .responsive-post-image {
                text-align: center;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .responsive-post-content {
                .text-wrapper {
                    border: 5px solid $color-brand-white;
                    position: relative;
                    top: -20px;
                    margin: 0 15px 5px 15px;
                    padding: 25px 18px;
                    .title {
                        @include BentonMedium();
                        font-size: 22px;
                        color: $color-brand-dark-brown;
                        letter-spacing: 0.34px;
                        line-height: 27px;
                    }
                    .date {
                        @include BentonMedium();
                        font-size: 14px;
                        color: #AAAAAE;
                        letter-spacing: 1.17px;
                        padding: 9px 0 6px 0;
                    }
                    .summary {
                        letter-spacing: 0.25px;
                        padding-bottom: 16px;
                    }
                    .link a {
                        @include TitlingGothic();
                        font-size: 12px;
                        color: $color-brand-brown;
                        letter-spacing: 0.75px;
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            .post-left,
            .post-right {
                display: none;
            }
            .responsive-container {
                display: block;
            }
        }
    }
}
