/*Wrapper*/
.wrapper {
	box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.4);
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

picture {
    width: 100%;
    display: block;

    img {
        width: 100%;
        display: block;
    }
}
img {
	max-width: 100%;
	height: auto;
}
.contents {
	max-width: 1440px;
	margin: 0 auto;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.product-container {
	padding: 0 80px;
	@media(max-width: 1025px) {
		padding: 0 20px;
	}
}
.page-wrapper {
	padding: 110px 0;
	@media(max-width: 1025px) {
		padding: 50px 0;
	}
}
.page-container {
	padding: 0 80px;
	margin: 0 auto;
	@media(max-width: 1025px) {
		padding: 0 20px;
	}
}
.clickable-div:hover {
	cursor: pointer;
}

// Contact Page
.contactlocation {
	margin-top: 20px;
	width: 50%;
	float: left;
	background-color: $color-brand-white;
	padding-right: 10px;
	&:last-of-type {
		padding-left: 10px;
	}
	h4 {
		padding: 15px 10px 15px 10px;
		margin: 0;
		background: $color-white;
	}
	p {
		min-height: 300px;
		font-size: 14px;
		margin: 0;
		&:first-of-type {
			background-color: #F7F7F7;
			padding: 10px 10px 10px 10px;
		}
		&:last-of-type {
			height: 430px;
		}
	}
	iframe {
		border: none;
	}
	@media(max-width: 1025px) {
		p {
			min-height: 200px;
		}
	}
	@media(max-width: 769px) {
		float: none;
		width: 100%;
		padding: 0 !important;
		p {
			min-height: 0;
		}
	}
}

// Default WP alignment classes
.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}


// WP Login Page
#login {
	h1 a {
		background-image: url('/assets/img/responsive-logo.svg');
		background-size: 184px 40px;
		width: 246px;
		height: 54px;
	}
	#loginform {
		@include BentonRegular();
		.submit input {
			background-color: $color-brand-brown;
			transition: background-color 0.5s ease;
			-ms-transition: background-color 0.5s ease;
			-webkit-transition: background-color 0.5s ease;
			-o-transition: background-color 0.5s ease;
			-moz-transition: background-color 0.5s ease;
			padding: 18px 21px 16px !important;
			display: inline-block;
			@include TitlingGothic();
			font-size: 12px !important;
			line-height: 12px;
			color: $color-white;
			letter-spacing: 0.75px;
			text-decoration: none;
			text-transform: uppercase;
			border: 0;
			border-radius: 0;
			text-shadow: none;
			height: auto;
			&:hover {
				background-color: $color-brand-black;
			}
		}
	}
}
