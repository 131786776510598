#projects {
    .intro-section {
        .intro-wrapper {
            position: relative;
            overflow: hidden;
            .intro-left {
                background-size: cover;
                width: 40%;
                float: left;
                height: 450px;
            }
            .intro-right {
                background-size: cover;
                background-position: bottom;
                width: 60%;
                float: right;
                height: 450px;
            }
            .intro-text-container {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                .intro-text {
                    width: 537px;
                    float: left;
                    margin-top: 107px;
                    margin-bottom: 107px;
                    margin-left: 520px;
    				color: $color-brand-white;
                    @media(max-width: 2412px) {
                        margin-left: 478px;
                    }
                    @media(max-width: 2300px) {
                        margin-left: 430px;
                    }
                    @media(max-width: 2190px) {
                        margin-left: 385px;
                    }
                    @media(max-width: 2030px) {
                        margin-left: 328px;
                    }
                    @media(max-width: 1897px) {
                        margin-left: 266px;
                    }
                    @media(max-width: 1757px) {
                        margin-left: 202px;
                    }
                    @media(max-width: 1590px) {
                        margin-left: 145px;
                    }
                    @media(max-width: 1444px) {
                        margin-left: 87px;
                    }
                    @media(max-width: 1350px) {
                        width: 509px;
                        margin-top: 107px;
                        margin-bottom: 107px;
                        margin-left: 72px;
                    }
                    @media(max-width: 1242px) {
                        width: 481px;
                        margin-top: 107px;
                        margin-bottom: 107px;
                        margin-left: 55px;
                    }
                    @media(max-width: 1124px) {
                        width: 450px;
                        margin-top: 107px;
                        margin-bottom: 107px;
                        margin-left: 30px;
                    }
                    @media(max-width: 1060px) {
                        width: 420px;
                        margin-top: 107px;
                        margin-bottom: 107px;
                        margin-left: 32px;
                    }
                    .text-wrapper {
                        border: 10px solid $color-white;
                        padding: 40px 0 96px 25px;
                    }
                }
            }
        }
        .intro-wrapper-responsive {
            display: none;
            .intro-top {
                img {
                    width: 100%;
                }
            }
            .intro-bottom {
                background-size: cover;
                padding: 0 18px 30px 18px;
                .intro-text {
                    position: relative;
                    top: -33px;
                    .text-wrapper {
                        border: 5px solid $color-white;
                        padding: 20px;
                        h1 {
                            font-size: 36px;
                            letter-spacing: 0.56px;
                            color: $color-brand-white;
                        }
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            .intro-wrapper {
                display: none;
            }
            .intro-wrapper-responsive {
                display: block;
            }
        }
        @media(max-width: 350px) {
            .intro-wrapper-responsive .intro-bottom .intro-text .text-wrapper h1 {
                font-size: 30px;
            }
        }
    }
    .projects-section {
        .projects-wrapper {
            .project-item {
                height: 320px;
                position: relative;
                overflow: hidden;
                &.first-item {
                    height: 640px;
                }
                &.large-right {
                    float: right;
                }
                .item-inner {
                    z-index: 5;
                    pointer-events: none;
                    position: absolute;
                    right: 30px;
                    bottom: 30px;
                    @include BentonMedium();
                    font-size: 14px;
                    letter-spacing: 0.17px;
                    background-color: #EEEAE4;
                    padding: 16px 14px;
                    .title {
                        color: $color-brand-black;
                        .ss-picture {
                            font-family: "SSStandard";
                            padding: 0 7px 0 17px;
                        }
                        span {
                            color: #71544E;
                        }
                    }
                }
                .project-background {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                    transition: all 2s;
                    &:hover {
                        z-index: 1;
                        -ms-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
            .filler-item {
                height: 320px;
                position: relative;
                background-size: cover;
                text-align: center;
                background-color: #EEEAE4;
                color: $color-brand-black;
                padding: 0 10px;
                .btn-brown {
                    margin-top: 25px;
                }
                &.tall {
                    height: 640px;
                    .filler-text p {
                        font-size: 40px;
                    }
                    .btn-brown {
                        position: absolute;
                        right: 30px;
                        bottom: 30px;
                    }
                }
            }
        }
        .load-more {
            background-color: $color-brand-white;
            height: 130px;
            text-align: center;
            .load-btn {
                border: 4px solid $color-brand-brown;
                display: inline-block;
                padding: 16px 24px;
                margin-top: 40px;
                cursor: pointer;
                a {
                    @include TitlingGothic();
                    font-size: 12px;
                    color: #70544F;
                    letter-spacing: 0.75px;
                    text-decoration: none;
                    text-transform: uppercase;
                }
                &:hover {
                    border-color: $color-brand-black;
                    a {
                        color: $color-brand-black;
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            .projects-wrapper {
                .project-item {
                    height: 450px;
                    &.first-item {
                        height: 450px;
                    }
                    &.large-right {
                        float: left;
                    }
                }
                .filler-item {
                    height: 450px;
                }
            }
        }
        @media(max-width: 769px) {
            .projects-wrapper {
                .project-item.large-right {
                    float: none;
                }
                .filler-item {
                    display: none !important;
                }
            }
        }
        @media(max-width: 500px) {
            .projects-wrapper {
                .project-item {
                    height: 320px;
                    &.first-item {
                        height: 320px;
                    }
                }
            }
        }
    }

}
/******** Project Detail page *********/
#project-single {
    background-color: $color-brand-white;
    .project-top {
        .image-gallery {
            width: 72%;
            float: left;
            position: relative;
            .project-image-carousel,
            .responsive-project-carousel {
                .slick-arrow {
                    z-index: 5;
                    width: 56px;
                    height: 56px;
                    &::before{
                        content: "";
                    }
                }
                .slick-prev {
                    left: 0;
                    background: url("/assets/img/gallery-left.svg") no-repeat center;
                    background-position-x: 12px;
                    background-color: rgba(238,234,228,.8);
                    border-radius: 0;
                }
                .slick-next {
                    right: 0;
                    background: url("/assets/img/gallery-right.svg") no-repeat center;
                    background-color: rgba(238,234,228,.8);
                    background-position-x: 20px;
                    border-radius: 0;
                }
            }
            .project-image-carousel {
                .slick-slide {
                    height: 720px;
                    background-size: cover;
                    background-position: bottom;
                }
            }
            .responsive-project-carousel {
                display: none;
            }
            .nav-container {
                position: absolute;
                bottom: -30px;
                width: 720px;
                .slick-track {
                    margin-left: 25px;
                    -webkit-transform: translate3d(0, 0, 0) !important;
                    -moz-transform: translate3d(0, 0, 0) !important;
                    -ms-transform: translate3d(0, 0, 0) !important;
                    -o-transform: translate3d(0, 0, 0) !important;
                    transform: translate3d(0, 0, 0) !important;
                }
                .img-wrapper {
                    margin-right: 20px;
                    width: 60px !important;
                    border-bottom: 5px solid transparent;
                    position: relative;
                    img {
                        border: 5px solid $color-brand-white;
                        border-bottom-width: 3px;
                        width: 60px;
                        height: 60px;
                    }
                    &::before {
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                        right: 0;
                        height: 5px;
                        content: ' ';
                        display: block;
                        opacity: 1;
                        width: 50px;
                        margin: 0 auto;
                    }
                    &.last-image {
                        margin-right: 0;
                        span {
                            height: 100%;
                            width: 100%;
                            top: 0;
                            left: 0;
                            position: absolute;
                            color: $color-brand-white;
                            @include BentonMedium();
                            background-color: rgba(54,42,38,.5);
                            text-align: center;
                            font-size: 22px;
                            line-height: 65px;
                        }
                    }
                    &.slick-current,
                    &:hover {
                        cursor: pointer;
                        &::before {
                            background: $color-brand-black;
                        }

                    }

                }
            }
            @media(max-width: 1025px) {
                width: 100%;
                float: none;
                .project-image-carousel {
                    display: none;
                }
                .responsive-project-carousel {
                    display: block;
                }
                .nav-container {
                    display: none;
                }
            }
        }
        .project-intro {
            width: 28%;
            float: left;
            padding: 0 24px;
            height: 720px;
            h1 {
                font-size: 32px;
                color: #362A26;
                letter-spacing: 0.44px;
                line-height: 32px;
                padding-bottom: 13px;
                margin-top: 0;
            }
            .location {
                @include TitlingGothic();
                font-size: 12px;
                color: #585859;
                letter-spacing: 0.47px;
                text-transform: uppercase;
                padding-bottom: 44px;
            }
            .description {
                padding-bottom: 64px;
                p {
                    font-size: 16px;
                    color: #242227;
                    letter-spacing: 0.25px;
                    line-height: 24px;
                }
            }
            .intro-products {
                .section-title {
                    @include TitlingGothic;
                    font-size: 12px;
                    color: #585859;
                    letter-spacing: 0.47px;
                    text-transform: uppercase;
                    padding-bottom: 30px;
                }
                .product-listing {
                    clear: both;
                    margin-bottom: 18px;
                    .thumb {
                        width: 50px;
                        height: 50px;
                        float: left;
                    }
                    .name {
                        float: left;
                        @include BentonMedium();
                        font-size: 16px;
                        color: $color-brand-black;
                        letter-spacing: 0.25px;
                        padding-left: 22px;
                        a {
                            @include BentonMedium();
                            font-size: 14px;
                            color: $color-brand-grey;
                            letter-spacing: 0.25px;
                            text-decoration: none;
                            &:hover {
                                color: $color-brand-black;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
            @media(max-width: 1025px) {
                width: 100%;
                float: none;
                margin-top: 45px;
                height: auto;
                .text-wrapper {
                    padding-top: 0 !important;
                }
                .responsive-left {
                    float: left;
                    width: 50%;
                    padding-right: 50px;
                    .location {
                        padding-bottom: 15px;
                    }
                }
                .responsive-right {
                    float: right;
                    width: 50%;
                    .intro-products .section-title {
                        padding-bottom: 15px;
                    }
                }
            }
            @media(max-width: 769px) {
                .responsive-left,
                .responsive-right {
                    float: none;
                    width: 100%;
                    padding-right: 0;
                }
            }
        }
    }
    .project-details {
        margin: 96px 0 76px;
        .details-container {
            width: 72%;
            .details-left,
            .details-right {
                .section-title {
                    @include TitlingGothic();
                    font-size: 12px;
                    color: #242227;
                    letter-spacing: 0.47px;
                    text-transform: uppercase;
                    border-bottom: 2px solid #D8D8D8;
                    padding-bottom: 12px;
                    margin-bottom: 27px;
                }
                h4 {
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.25px;
                    line-height: 24px;
                    text-transform: uppercase;
                    margin: 0;
                }
                p {
                    font-size: 16px;
                    letter-spacing: 0.25px;
                    line-height: 24px;
                    &:not(last-child) {
                        margin-bottom: 33px;
                    }
                }
            }
            .details-right {
                .product-switcher {
                    .product-btn {
                        width: 60px;
                        height: 60px;
                        display: inline-block;
                        border: 5px solid transparent;
                        .thumb {
                            height: 50px;
                        }
                        &.active {
                            border-color: $color-brand-grey;
                        }
                        &:hover {
                            border-color: $color-brand-grey;
                            cursor: pointer;
                        }
                    }
                }
                .dimension-wrapper {
                    max-width: 326px;
                    margin-bottom: 33px;
                    h4 {
                        margin-bottom: 20px;
                    }
                    .length {
                        padding-left: 78px;
                    }
                    img {
                        display: block;
                        max-width: 100%;
                    }
                    .height {
                        float: right;
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            margin: 20px 0;
            padding: 0 24px;
            .details-container {
                width: 100%;
                .details-left {
                    padding-right: 50px;
                }
            }
        }
        @media(max-width: 769px) {
            .details-container {
                .details-left {
                    padding-right: 0;
                }
            }
        }
    }
}
