#wood-flooring,
#trade {
    .intro-section {
        .intro-wrapper {
            position: relative;
            overflow: hidden;
            .intro-left {
                background-size: cover;
                background-position: bottom;
                width: 40%;
                float: left;
            }
            .intro-right {
                background-size: cover;
                background-position: bottom;
                width: 60%;
                float: right;
            }
            .intro-text-container {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                .intro-text {
                    width: 537px;
                    float: left;
                    margin-top: 182px;
                    margin-bottom: 308px;
                    margin-left: 500px;
                    color: $color-brand-white;
                    @media(max-width: 2416px) {
                        margin-left: 466px;
                    }
                    @media(max-width: 2305px) {
                        margin-left: 423px;
                    }
                    @media(max-width: 2197px) {
                        margin-left: 372px;
                    }
                    @media(max-width: 2064px) {
                        margin-left: 324px;
                    }
                    @media(max-width: 1965px) {
                        margin-left: 282px;
                    }
                    @media(max-width: 1839px) {
                        margin-left: 237px;
                    }
                    @media(max-width: 1739px) {
                        margin-left: 190px;
                    }
                    @media(max-width: 1639px) {
                        margin-left: 150px;
                    }
                    @media(max-width: 1539px) {
                        margin-left: 110px;
                    }
                    @media(max-width: 1439px) {
                        margin-left: 70px;
                    }
                    @media(max-width: 1350px) {
                        width: 509px;
                        margin-top: 162px;
                        margin-bottom: 260px;
                        margin-left: 72px;
                    }
                    @media(max-width: 1242px) {
                        width: 481px;
                        margin-top: 142px;
                        margin-bottom: 220px;
                        margin-left: 55px;
                    }
                    @media(max-width: 1124px) {
                        width: 450px;
                        margin-top: 107px;
                        margin-bottom: 160px;
                        margin-left: 30px;
                    }
                    @media(max-width: 1060px) {
                        width: 420px;
                        margin-top: 122px;
                        margin-bottom: 190px;
                        margin-left: 32px;
                    }
                    .text-wrapper {
                        border: 10px solid $color-white;
                        padding: 55px 35px;
                    }
                    .description {
                        color: $color-brand-white;
                        p {
                            width: 90%;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
        .intro-wrapper-responsive {
            display: none;
            margin-bottom: -10px;
            .intro-top {
                img {
                    width: 100%;
                }
            }
            .intro-bottom {
                background-size: cover;
                padding: 0 18px;
                .intro-text {
                    position: relative;
                    top: -33px;
                    .text-wrapper {
                        border: 5px solid $color-white;
                        padding: 20px;
                        h1 {
                            font-size: 36px;
                            letter-spacing: 0.56px;
                            color: $color-brand-white;
                        }
                        .description {
                            color: $color-brand-white;
                            p {
                                font-size: 16px;
                                letter-spacing: 0.25px;
                                line-height: 21px;
                                margin-bottom: 23px;
                            }
                        }
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            .intro-wrapper {
                display: none;
            }
            .intro-wrapper-responsive {
                display: block;
            }
        }
        @media(max-width: 350px) {
            .intro-wrapper-responsive .intro-bottom .intro-text .text-wrapper h1 {
                font-size: 30px;
            }
        }
    }
    .collections {
        .row {
            //max-height: 490px;
            .collection-image img {
                width: 100%;
            }
            .collection-text {
                background-size: cover;
                height: 480px;
                a {
                    text-decoration: none;
                }
                .text-wrapper {
                    width: 80%;
                    color: $color-brand-dark-brown;
                    &:hover {
                        opacity: .5;
                    }
                    @media(min-width: 1025px) {
                        @include center();
                    }
                    .description {
                        font-size: 18px;
                        letter-spacing: 0.28px;
                        line-height: 28px;
                        padding-bottom: 30px;
                    }
                    .link {
                        @include TitlingGothic();
                        font-size: 12px;
                        letter-spacing: 0.75px;
                        color: $color-brand-brown;
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                    &.white {
                        color: $color-brand-white;
                        .link {
                            color: $color-brand-white;
                        }
                    }
                }
            }
            &:nth-child(even) {
                .collection-image {
                    float: right;
                }
            }
        }
        @media(max-width: 1025px) {
            .row {
                max-height: none;
                .collection-image {
                    max-height: 640px;
                    float: none !important;
                }
                .collection-text {
                    height: auto !important;
                    width: 100%;
                    .text-wrapper {
                        width: 100%;
                        padding: 25px 15px;
                        h2 {
                            margin: 0;
                            font-size: 23px;
                            letter-spacing: 0.32px;
                        }
                        .description {
                            font-size: 16px;
                            letter-spacing: 0.25px;
                            line-height: 21px;
                            padding: 11px 0 25px;
                        }
                    }
                }
            }
        }
    }
}
/******** Product Detail Page *********/
#wood-flooring-single,
#trade-flooring-single {
    .hero-section {
        .hero-wrapper {
            position: relative;
            overflow: hidden;
            .hero-left {
                background-size: cover;
                background-position: bottom;
                height: 700px;
                .caption {
                    position: absolute;
                    @include BentonMedium();
                    font-size: 14px;
                    color: #EEEAE4;
                    letter-spacing: 0.39px;
                    bottom: 32px;
                    left: 80px;;
                }
            }
            .hero-right {
                background-size: cover;
                height: 700px;
            }
            .hero-text-container {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                color: $color-brand-white;
                .hero-text {
                    width: 451px;
                    float: right;
                    margin-top: 111px;
                    margin-bottom: 66px;
                    margin-right: 442px;
                    @media(max-width: 2444px) {
                        margin-right: 415px;
                    }
                    @media(max-width: 2360px) {
                        margin-right: 390px;
                    }
                    @media(max-width: 2290px) {
                        margin-right: 360px;
                    }
                    @media(max-width: 2220px) {
                        margin-right: 338px;
                    }
                    @media(max-width: 2133px) {
                        margin-right: 309px;
                    }
                    @media(max-width: 2047px) {
                        margin-right: 283px;
                    }
                    @media(max-width: 1970px) {
                        margin-right: 258px;
                    }
                    @media(max-width: 1893px) {
                        margin-right: 232px;
                    }
                    @media(max-width: 1817px) {
                        margin-right: 205px;
                    }
                    @media(max-width: 1739px) {
                        margin-right: 179px;
                    }
                    @media(max-width: 1660px) {
                        margin-right: 154px;
                    }
                    @media(max-width: 1583px) {
                        margin-right: 129px;
                    }
                    @media(max-width: 1511px) {
                        margin-right: 103px;
                    }
                    @media(max-width: 1427px) {
                        margin-right: 76px;
                    }
                    @media(max-width: 1352px) {
                        width: 436px;
                        margin-right: 65px;
                    }
                    @media(max-width: 1258px) {
                        width: 419px;
                        margin-right: 50px;
                    }
                    @media(max-width: 1164px) {
                        width: 395px;
                        margin-top: 81px;
                        margin-bottom: 55px;
                        margin-right: 43px;
                    }
                    @media(max-width: 1070px) {
                        margin-right: 11px;
                    }
                    .text-wrapper {
                        border: 10px solid $color-white;
                        padding: 34px 30px;
                    }
                    .description,
                    .btn-brown,
                    .social-links {
                        margin-left: 40px;
                    }
                    .description {
                        margin-top: 27px;
                        margin-bottom: 48px;
                        color: $color-brand-white;
                        p {
                            line-height: 28px;
                        }
                    }
                    .social-links {
                        margin-top: 38px;
                        li {
                            padding-right: 34px;
                            a {
                                font-size: 24px;
                                &.houzz img {
                                    height: 29px;
                                }
                            }
                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
        .hero-wrapper-responsive {
            display: none;
            .hero-top {
                max-height: 640px;
                overflow: hidden;
                img {
                    width: 100%;
                }
            }
            .hero-bottom {
                background-color: $color-brand-black;
                padding: 18px;
                .hero-text {
                    color: $color-brand-white;
                    position: relative;
                    top: -50px;
                    .text-wrapper {
                        border: 5px solid $color-white;
                        padding: 20px;
                        h1 {
                            font-size: 36px;
                            letter-spacing: 0.56px;
                        }
                        .description p {
                            color: $color-brand-white;
                            font-size: 16px;
                            letter-spacing: 0.25px;
                            line-height: 21px;
                            margin-bottom: 23px;
                        }
                        .hero-button {
                            text-align: center;
                            width: 290px;
                            margin: 0 auto;
                            display: block;
                        }
                        .social-links {
                            margin-top: 38px;
                            text-align: center;
                            li {
                                padding-right: 37px;
                                a {
                                    font-size: 24px;
                                    &.houzz img {
                                        height: 29px;
                                    }
                                }
                                &:last-child {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            .hero-wrapper {
                display: none;
            }
            .hero-wrapper-responsive {
                display: block;
            }
        }
        @media(max-width: 380px) {
            .hero-wrapper-responsive {
                .hero-bottom .hero-text .text-wrapper .hero-button {
                    width: 240px;
                }
            }
        }
    }
    .details-section {
        padding: 95px 0 75px;
        background: $color-white;
        .details-wrapper {
            margin-top: 70px;
            h4 {
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.25px;
                line-height: 24px;
                text-transform: uppercase;
                margin: 0;
            }
            p {
                font-size: 16px;
                letter-spacing: 0.25px;
                line-height: 24px;
                &:not(last-child) {
                    margin-bottom: 33px;
                }
            }
            .details-left {
                .dimension-wrapper {
                    max-width: 326px;
                    margin-bottom: 33px;
                    h4 {
                        margin-bottom: 20px;
                    }
                    .length {
                        padding-left: 78px;
                    }
                    img {
                        display: block;
                        max-width: 100%;
                    }
                    .height {
                        float: right;
                    }
                }
                .features,
                .options {
                    ul {
                        padding-left: 16px;
                        li {
                            @include BentonRegular();
                        }
                    }
                }
            }
            .details-right {
                padding-left: 70px;
                .cert {
                    margin-bottom: 33px;
                    .image-holder {
                        float: left;
                        width: 30%;
                        padding-right: 20px;
                        border-right: 1px solid $color-brand-white;
                        text-align: center;
                        img {
                            max-width: 100%;
                            height: auto;
                            max-height: 100px;
                        }
                    }
                    .description {
                        width: 70%;
                        float: left;
                        color: $color-brand-grey;
                        padding-left: 20px;
                        font-size: 14px;
                    }
                    &:first-of-type {
                        margin-top: 33px;
                    }
                }
                .file {
                    margin-top: 5px;
                    img {
                        padding: 0 5px 5px 0;
                    }
                    a {
                        color: $color-brand-black;
                        text-decoration: none;
                        &:hover {
                            opacity: .5;
                        }
                        span {
                            color: $color-brand-grey;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            .details-wrapper {
                .details-right {
                    padding-left: 35px;
                }
            }
        }
        @media(max-width: 769px) {
            padding: 34px 0;
            .details-wrapper {
                margin-top: 15px;
                .details-right {
                    padding-left: 0;
                    margin-top: 33px;
                }
            }
        }
        @media(max-width: 381px) {
            .details-wrapper .details-left .dimension-wrapper {
                .length {
                    padding-left: 45px;
                }
            }
        }
    }
    .finishes-section {
        .finishes-left,
        .finishes-right {
            height: 720px;
        }
        .finishes-left {
            background-color: $color-brand-black;
            color: $color-brand-white;
            padding: 0 20px;
            .left-wrapper {
                padding-top: 161px;
                max-width: 620px;
                margin: 0 auto;
                h2 {
                    margin-top: 0;
                }
            }
            .count {
                font-size: 16px;
                color: #FFFFFF;
                letter-spacing: 0.63px;
            }
            .finish-dropdown {
                margin-top: 55px;
                button {
                    background-color: $color-brand-black;
                    border: 2px solid $color-brand-grey;
                    padding: 10px;
                    width: 100%;
                    text-align: left;
                    color: #D8D8D8;
                    &:hover {
                        border-color: $color-white;
                    }
                    #selected {
                        @include TitlingGothic();
                        font-size: 12px;
                        letter-spacing: 0.47px;
                        text-transform: uppercase;
                        line-height: 50px;
                        @media screen and (max-width: $screen-sm) {
                            font-size: 10px;
                        }
                        img {
                            max-height: 50px;
                            padding-right: 30px;
                        }
                    }
                    .ss-navigatedown {
                        float: right;
                        padding-right: 16px;
                        font-size: 18px;
                        letter-spacing: 0.71px;
                        &::before {
                            font-family: 'SSAir';
                            line-height: 50px;
                        }
                    }
                }
                .finish-menu {
                    background-color: $color-brand-black;
                    width: 100%;
                    li a {
                        @include TitlingGothic();
                        font-size: 12px;
                        letter-spacing: 0.47px;
                        text-transform: uppercase;
                        text-decoration: none;
                        color: #D8D8D8;
                        margin-bottom: 15px;
                        padding-left: 12px;
                        @media screen and (max-width: $screen-sm) {
                            font-size: 10px;
                            padding-left: 0;
                        }
                        img {
                            max-height: 50px;
                            padding-right: 30px;
                        }
                        &:hover {
                            background-color: $color-brand-white;
                            color: $color-brand-black;
                        }
                    }
                }
            }
            .finish-description {
                margin-top: 45px;
                > div {
                    display: none;
                    font-size: 18px;
                    color: #EBEBEB;
                    letter-spacing: 0.28px;
                    line-height: 28px;
                }
            }
        }
        .finishes-right {
            position: relative;
            > div {
                display: none;
            }
            .finish-carousel .slick-slide {
                height: 720px;
                background-size: cover;
            }
            .nav-container {
                position: absolute;
                bottom: -30px;
                width: 100%;
                .slick-list {
                    overflow: visible;
                }
                .slick-track {
                    margin-left: 25px;
                    width: 100% !important;
                    -webkit-transform: translate3d(0, 0, 0) !important;
                    -moz-transform: translate3d(0, 0, 0) !important;
                    -ms-transform: translate3d(0, 0, 0) !important;
                    -o-transform: translate3d(0, 0, 0) !important;
                    transform: translate3d(0, 0, 0) !important;
                }
                .img-wrapper {
                    margin-right: 30px;
                    width: 60px !important;
                    position: relative;
                    img {
                        border: 5px solid $color-brand-white;
                        width: 60px;
                        height: 60px;
                    }
                    &::before {
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                        right: 0;
                        height: 5px;
                        content: ' ';
                        display: block;
                        opacity: 1;
                        width: 50px;
                        margin: 0 auto;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    &.slick-current,
                    &:hover {
                        cursor: pointer;
                        &::before {
                            background: $color-brand-black;
                        }
                    }
                }
            }
        }
        .active {
            display: block !important;
        }
        @media(max-width: 1025px) {
            .finishes-left,
            .finishes-right {
                height: auto;
            }
            .finishes-left {
                padding: 0 20px;
                .left-wrapper {
                    padding: 40px 0 !important;
                    margin: 0;
                    max-width: none;
                    .finish-dropdown {
                        margin-top: 23px;
                        max-width: 400px;
                        button {
                            height: 60px;
                            padding: 10px 20px;
                            #selected {
                                line-height: inherit;
                                img {
                                    display: none;
                                }
                            }
                            .ss-navigatedown::before {
                                line-height: normal;
                            }
                        }
                    }
                    .finish-description {
                        margin-top: 23px;
                    }
                }
            }
            .finishes-right {
                .finish-carousel .slick-slide {
                    height: 380px;
                }
                .nav-container {
                    .slick-track {
                        margin-left: 10px;
                        width: 100% !important;
                    }
                    .img-wrapper {
                        margin-right: 15px;
                    }
                }
            }
        }
        @media(max-width: 380px) {
            .finishes-left .left-wrapper .finish-dropdown button {
                padding: 10px;
            }
        }
    }
    .project-picture-section {
        padding-top: 75px;
        background-color: $color-brand-white;
        .top {
            margin-bottom: 28px;
            h2 {
                float: left;
                margin: 0;
            }
            .pager {
                float: right;
                > div {
                    display: inline-block;

                }
                .image-count {
                    font-size: 14px;
                    letter-spacing: 0.55px;
                    padding: 0 17px;
                }
                .slick-arrow:hover {
                    cursor: pointer;
                }
            }
            .gallery-link {
                clear: both;
                float: left;
                padding-top: 5px;
            }
        }
        .slick-slide {
            max-height: 445px;
        }
        .pager-responsive {
            display: none;
            text-align: center;
            padding: 20px 0;
            > div {
                display: inline-block;
            }
            .image-count {
                font-size: 14px;
                letter-spacing: 0.55px;
                padding: 0 17px;
            }
        }
        @media(max-width: 1025px) {
            padding-top: 66px;
            .top {
                margin-bottom: 20px;
                .pager {
                    display: none;
                }
            }
            .slick-slide {
                height: auto;
            }
            .pager-responsive {
                display: block;
            }
        }
    }
}
