// CTA Template
section.cta-section {
    background-size: cover;
    background-position: bottom;
    .cta-inner {
        text-align: center;
        padding: 80px 0;
        .title  {
            @include BentonMedium();
            font-size: 32px;
            letter-spacing: 0.44px;
            line-height: 32px;
        }
        .description {
            font-size: 18px;
            letter-spacing: 0.28px;
            line-height: 28px;
            padding: 23px 0 31px;
        }
    }
    @media(max-width: 1025px) {
        .cta-inner {
            padding: 35px;
            .title  {
                font-size: 23px;
                letter-spacing: 0.32px;
                line-height: 23px;
            }
            .description {
                font-size: 16px;
                letter-spacing: 0.25px;
                line-height: 21px;
                padding-bottom: 28px;
            }
        }

    }
}

// Social Links Template
.social-links {
	li {
		list-style: none;
		display: inline-block;
		a {
			font-family: "SSSocialRegular";
			color: $color-brand-white;
			font-size: 34px;
			letter-spacing: 2.66px;
			text-decoration: none;
			&:not(.houzz) {
				vertical-align: middle;
			}
            &:hover {
                opacity: .5;
            }
		}
	}
}

// Hero Template
.intro-section {
	.intro-wrapper {
		position: relative;
		overflow: hidden;
		.intro-left {
			background-size: cover;
			width: 40%;
			float: left;
            height: 450px;
		}
		.intro-right {
			background-size: cover;
			background-position: bottom;
			width: 60%;
			float: right;
            height: 450px;
		}
		.intro-text-container {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			.intro-text {
				width: 537px;
				float: left;
				margin-top: 107px;
				margin-bottom: 107px;
				margin-left: 520px;
				color: $color-brand-white;
                @media(max-width: 2412px) {
                    margin-left: 478px;
                }
                @media(max-width: 2300px) {
                    margin-left: 430px;
                }
                @media(max-width: 2190px) {
                    margin-left: 385px;
                }
                @media(max-width: 2030px) {
                    margin-left: 328px;
                }
                @media(max-width: 1897px) {
                    margin-left: 266px;
                }
                @media(max-width: 1757px) {
                    margin-left: 202px;
                }
                @media(max-width: 1590px) {
                    margin-left: 145px;
                }
                @media(max-width: 1444px) {
                    margin-left: 87px;
                }
				@media(max-width: 1350px) {
					width: 509px;
					margin-top: 107px;
					margin-bottom: 107px;
					margin-left: 72px;
				}
				@media(max-width: 1242px) {
					width: 481px;
					margin-top: 107px;
					margin-bottom: 107px;
					margin-left: 55px;
				}
				@media(max-width: 1124px) {
					width: 450px;
					margin-top: 107px;
					margin-bottom: 107px;
				}
				@media(max-width: 1060px) {
					width: 420px;
					margin-top: 107px;
					margin-bottom: 107px;
				}
				.text-wrapper {
					border: 10px solid $color-white;
					padding: 40px 25px 96px;
				}
			}
		}
	}
	.intro-wrapper-responsive {
		display: none;
		.intro-top {
			img {
				width: 100%;
			}
		}
		.intro-bottom {
			background-size: cover;
			padding: 0 18px 30px 18px;
			.intro-text {
				position: relative;
				top: -33px;
				.text-wrapper {
					border: 5px solid $color-white;
					padding: 20px;
					h1 {
						font-size: 36px;
						letter-spacing: 0.56px;
						color: $color-brand-white;
					}
				}
			}
		}
	}
	@media(max-width: 1025px) {
		.intro-wrapper {
			display: none;
		}
		.intro-wrapper-responsive {
			display: block;
		}
	}
	@media(max-width: 380px) {
		.intro-wrapper-responsive .intro-bottom .intro-text .text-wrapper h1 {
			font-size: 30px;
		}
	}
}
