#about-us {
    .text-section {
        padding: 150px 0;
        background-color: $color-brand-white;
        .text-wrapper {
            float: left;
            padding-left: 80px;
            h2 {
                margin: 0 0 42px 0;
            }
        }
        .image {
            padding-left: 107px;
            margin-top: 74px;
            float: right;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        &.image-left {
            background-color: $color-brand-black;
            color: $color-brand-white;
            .text-wrapper {
                padding-left: 0;
                padding-right: 80px;
                float: right;
            }
            .image {
                padding-left: 0;
                padding-right: 107px;
                float: left;
            }
        }
        @media(max-width: 1025px) {
            padding: 75px 0;
            .text-wrapper {
                padding-left: 20px;
            }
            .image {
                padding-left: 50px;
            }
            &.image-left {
                .text-wrapper {
                    padding-right: 20px;
                }
                .image {
                    padding-right: 50px;
                }
            }
        }
        @media(max-width: 769px) {
            .text-wrapper {
                padding: 0 20px !important;
                float: none !important;
                h2 {
                    margin-bottom: 21px;
                }
            }
            .image {
                float: none !important;
                margin: 0 auto 40px auto !important;
                padding: 0 20px !important;
                text-align: center;
            }
        }
    }
    .team-section {
        padding: 110px 80px 40px;
        background: $color-white;
        h2 {
            margin-bottom: 75px;
        }
        .team-member {
            font-size: 16px;
            margin-bottom: 75px;
            text-align: center;
            padding-right: 20px;
            img {
                max-width: 100%;
                height: auto;
            }
            .team-text-wrapper {
                padding-left: 44px;
            }
            .name {
                @include BentonMedium();
                text-align: left;
                margin: 18px 0 9px;
            }
            .title {
                text-align: left;
                font-size: 14px;
            }
        }
        @media(max-width: 1025px) {
            padding: 75px 20px 0;
            .team-member {
                margin-bottom: 50px;
            }
        }
    }
}
