/*Body*/
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include BentonRegular();
    letter-spacing: 0.16px;
    color: $color-brand-black;
}
a.skip-link {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
}
html, body {
    width: 100%;
    height: 100%;
    background-color: $color-brand-white;
}
a {
    text-decoration: underline;
    color: $color-brand-brown;

    &:hover {
        color: $color-brand-red;
    }
}
h1, h2, h3, h4, h5, h6, blockquote, cite, p {
    -webkit-text-shadow: none;
    text-shadow: none;
}
h1 {
    @include BentonMedium();
    font-size: 48px;
    letter-spacing: 0.43px;
    line-height: 48px;
    @media(max-width: 1025px) {
        font-size: 36px;
        letter-spacing: 0.56px;
        line-height: 36px;
    }
}
h2 {
    @include BentonMedium();
    font-size: 32px;
    letter-spacing: 0.44px;
    line-height: 32px;
    @media(max-width: 1025px) {
        font-size: 23px;
        letter-spacing: 0.32px;
        line-height: 23px;
    }
}
h3 {
    @include BentonMedium();
    font-size: 23px;
    letter-spacing: 0.27px;
    line-height: 27px;
    @media(max-width: 1025px) {
        font-size: 20px;
        letter-spacing: 0.24px;
        line-height: 27px;
    }
}
p {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 36px;
    &:last-of-type {
        margin: 0;
    }
    @media(max-width: 1025px) {
        font-size: 16px;
        letter-spacing: 0.25px;
        line-height: 21px;
    }
}
ul {
    margin: 0;
    li {
        @include BentonRegular();
    }
}
strong {
    //@include sans-bold;
}
picture {
    display: block;
}

.btn-brown {
    background-color: $color-brand-brown;
    transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    padding: 18px 21px 16px;
    display: inline-block;
    @include TitlingGothic();
    font-size: 12px;
    line-height: 12px;
    color: $color-white;
    letter-spacing: 0.75px;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
        background-color: $color-brand-black;
        text-decoration: none;
        color: $color-white;
    }
}
.gform_wrapper,
.gform_confirmation_wrapper {
    background-color: $color-white;
    padding: 58px 120px;
    //margin: 0 !important;
    @media(max-width: 1200px) {
        //margin: 0 20px !important;
        padding: 40px 80px;
    }
    @media(max-width: 769px) {
        margin: 0 !important;
        padding: 40px 20px;
    }
    .gform_body input {
        background-color: $color-brand-white;
        border: 0;
        height: 32px;
        &[type='checkbox'] {
            background-color: transparent;
        }
    }
    .gform_body select {
        background-color: $color-brand-white;
        border: 0;
        height: 32px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-border-radius: 0px;
        background-image: url("/assets/img/select-arrow.svg");
        background-position: 99% 50%;
        background-repeat: no-repeat;
        background-size: 16px;
        &::-ms-expand {
            display: none;
        }
    }
    .gform_body textarea {
        background-color: $color-brand-white;
        border: 0;
    }
    .gfield_label,
    .ginput_container {
        margin: 0 !important;
        @include BentonRegular();
    }
    input.gform_button {
        background-color: $color-brand-brown;
        transition: background-color 0.5s ease;
        -ms-transition: background-color 0.5s ease;
        -webkit-transition: background-color 0.5s ease;
        -o-transition: background-color 0.5s ease;
        -moz-transition: background-color 0.5s ease;
        padding: 18px 21px 16px !important;
        display: inline-block;
        @include TitlingGothic();
        font-size: 12px !important;
        line-height: 12px;
        color: $color-white;
        letter-spacing: 0.75px;
        text-decoration: none;
        text-transform: uppercase;
        border: 0;
        border-radius: 0;
        &:hover {
            background-color: $color-brand-black;
        }
    }
    .gfield_checkbox {
        input {
            margin-top: 0 !important;
        }
        label {
            margin-top: 3px !important;
        }
    }
    @media(max-width: 380px){
        .ginput_recaptcha {
            transform:scale(0.77);
            -webkit-transform:scale(0.77);
            transform-origin:0 0;
            -webkit-transform-origin:0 0;
        }
    }
}
.feature-text {
    font-size: 18px;
    color: $color-brand-dark-brown;
    letter-spacing: 0.28px;
    line-height: 28px;
}
.detail-text {
    font-size: 16px;
    color: $color-brand-black;
    letter-spacing: 0.25px;
    line-height: 24px;
}
