#site-header {
    .header-wrapper {
        text-align: right;
        height: 85px;
        background-color: $color-white;
        .custom-logo-link {
            float: left;
        }
        .menu-items {
            display: inline-block;
            ul {
                list-style: none;
                overflow: auto;
                padding: 0;
                li {
                    float: left;
                    margin: 0 15px;
                    position: relative;
                    a {
                        font-size: 15px;
                        color: $color-brand-grey;
                        letter-spacing: 1.17px;
                        line-height: 85px;
                        text-decoration: none;
                        text-transform: uppercase;
                        border-bottom: 4px solid transparent;
                        padding-bottom: 11px;

                    }
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    &.current-menu-item,
                    &:hover {
                        a {
                            border-bottom-color: $color-brand-brown;
                        }
                    }
                    @media (max-width: 1200px) {
                        margin: 0 7px;
                        a {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .get-quote {
            float: right;
            margin: 15px 20px 0 40px;
            @media (max-width: 1200px) {
                margin: 15px 10px 0 10px
            }
        }
    }
    .responsive-header-wrapper {
        display: none;
        position: fixed;
        z-index: 10;
        width: 100%;
        background: $color-white;
        >div {
            height: 60px;
            position: relative;
            >a {
                line-height: 60px;
                padding-left: 18px;
            }
            >a:not(.logo) {
                float: right;
                padding-left: 0;
                padding-right: 18px;
            }
            &.menu-opened {
                background: $color-brand-black;
                display: none;
                .menu-wrapper {
                    position: absolute;
                    z-index: 5;
                    width: 100%;
                    background: $color-brand-black;
                    padding-bottom: 95px;
                    .menu-items ul {
                        padding: 0;
                        list-style: none;
                        text-align: center;
                        li {
                            width: 100%;
                            line-height: 60px;
                            border-top: 1px solid rgba(216,216,216,.10);
                            a {
                                color: $color-white;
                                letter-spacing: 1.25px;
                                line-height: 66px;
                                text-decoration: none;
                                text-transform: uppercase;
                                &:hover {
                                    opacity: .5;
                                }
                            }
                        }
                    }
                    .get-quote {
                        margin: 0 auto;
                        padding: 0 15px;
                        text-align: center;
                        .btn-brown {
                            padding: 16px 80px;
                        }
                    }
                }
            }
        }
        &.show-menu {
            .menu-closed {
                display: none;
            }
            .menu-opened {
                display: block;
            }
        }
    }
    @media(max-width: 1025px) {
        .header-wrapper {
            display: none;
        }
        .responsive-header-wrapper {
            display: block;
        }
    }
}
