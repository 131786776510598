/*Border Radius*/
$border-radius-base: 0px;
$border-radius-small: 0px;
$border-radius-large: 0px;

/*Fonts*/
$font-size-base: 16px !default;
$font-size-sm: 11px;
$font-size-md: 16px;
$font-size-lg: 24px;

/*Navbar*/
$navbar-margin-bottom:  0;
$navbar-border-radius:  0;

/*Navbar - Links*/
$navbar-default-link-color: #000 !default;
$navbar-default-link-hover-color: #439B57 !default;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: #439B57 !default;
$navbar-default-link-active-bg: transparent !default;

/*Navbar - Toggle*/
$navbar-default-toggle-hover-bg: transparent !default;
$navbar-default-toggle-icon-bar-bg: #000 !default;
$navbar-default-toggle-border-color: transparent !default;

/*Forms*/
$input-height-base: 35px;

/*Buttons*/
$btn-default-border: transparent !default;

/*Placeholder text color*/
$input-color-placeholder: #4D4D4D !default;

/*Popovers*/

//** Popover body background color
$popover-bg:                          #F5F5F5 !default;
$popover-max-width:                   500px !default;
$popover-border-color:                #E3E3E3 !default;
$popover-fallback-border-color:       #E3E3E3 !default;

$popover-arrow-width:                 15px !default;
$popover-arrow-color:                 #F5F5F5 !default;

$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
$popover-arrow-outer-color:           fade_in($popover-border-color, 0.05) !default;
$popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%) !default;