#blog {
    .featured-post-section {
        background: $color-white;
        .post-left {
            position: relative;
            padding: 80px 0 80px 80px;
            @media (max-width: 1242px) {
                padding-left: 55px;
            }
            .tile-heading {
                padding-bottom: 38px;
                h2 {
                    color: $color-brand-black;
                    letter-spacing: 0.44px;
                    margin-bottom: 8px;
                }
                a {
                    @include TitlingGothic();
                    font-size: 12px;
                    letter-spacing: 0.75px;
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
            .post-text {
                position: absolute;
                width: 94%;
                z-index: 1;
                .text-wrapper {
                    border: 10px solid $color-brand-white;
                    padding: 34px 30px;
                    @include BentonMedium();
                    div {
                        max-width: 60%;
                    }
                    .title {
                        font-size: 26px;
                        letter-spacing: 0.43px;
                        line-height: 36px;
                        padding-bottom: 29px;
                    }
                    .date {
                        font-size: 14px;
                        color: $color-brand-brown;
                        letter-spacing: 1.17px;
                        text-transform: uppercase;
                        padding-bottom: 5px;
                    }
                    .summary {
                        @include BentonRegular();
                        font-size: 16px;
                        letter-spacing: 0.25px;
                        line-height: 26px;
                        padding-bottom: 21px;
                    }
                    .link a {
                        @include TitlingGothic();
                        font-size: 12px;
                        letter-spacing: 0.75px;
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .post-right {
            background-size: cover;
            height: 580px;
        }
        .responsive-container {
            display: none;
            .responsive-post-top {
                text-align: center;
                padding: 30px 0;
                h2 {
                    font-size: 23px;
                    letter-spacing: 0.91px;
                    margin: 0 0 6px 0;
                }
                a {
                    @include TitlingGothic();
                    font-size: 12px;
                    letter-spacing: 0.75px;
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
            .responsive-post-image {
                text-align: center;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .responsive-post-content {
                .text-wrapper {
                    border: 5px solid $color-brand-white;
                    position: relative;
                    top: -20px;
                    margin: 0 15px 5px 15px;
                    padding: 25px 18px;
                    .title {
                        @include BentonMedium();
                        font-size: 22px;
                        color: $color-brand-dark-brown;
                        letter-spacing: 0.34px;
                        line-height: 27px;
                    }
                    .date {
                        @include BentonMedium();
                        font-size: 14px;
                        color: #AAAAAE;
                        letter-spacing: 1.17px;
                        padding: 9px 0 6px 0;
                    }
                    .summary {
                        letter-spacing: 0.25px;
                        padding-bottom: 16px;
                    }
                    .link a {
                        @include TitlingGothic();
                        font-size: 12px;
                        color: $color-brand-brown;
                        letter-spacing: 0.75px;
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            .post-left,
            .post-right {
                display: none;
            }
            .responsive-container {
                display: block;
            }
        }
    }
    .posts-section {
        background-color: $color-brand-white;
        padding: 80px 0;
        .post-container {
            padding-right: 33px;
            margin-bottom: 39px;
            &:nth-child(3n+3) {
                padding-right: 0;
            }
            .post-image {
                height: 383px;
                background-size: cover;
                background-position: bottom;
            }
            .responsive-post-image {
                display: none;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .post-text {
                background-color: $color-white;
                .text-wrapper {
                    padding: 20px 30px 30px;
                    .title {
                        @include BentonMedium();
                        font-size: 23px;
                        color: $color-brand-black;
                        letter-spacing: 0.27px;
                        line-height: 27px;
                        margin-bottom: 16px;
                    }
                    .date {
                        @include BentonMedium();
                        font-size: 14px;
                        line-height: 14px;
                        color: $color-brand-brown;
                        letter-spacing: 1.17px;
                        text-transform: uppercase;
                        margin-bottom: 7px;
                    }
                    .summary {
                        margin-bottom: 20px;
                    }
                    .link {
                        a {
                            @include TitlingGothic();
                            font-size: 12px;
                            color: $color-brand-brown;
                            letter-spacing: 0.75px;
                            text-transform: uppercase;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .load-more-posts {
            background-color: $color-brand-white;
            height: 130px;
            text-align: center;
            .load-btn {
                border: 4px solid $color-brand-brown;
                display: inline-block;
                padding: 16px 24px;
                margin-top: 40px;
                a {
                    @include TitlingGothic();
                    font-size: 12px;
                    color: #70544F;
                    letter-spacing: 0.75px;
                    text-decoration: none;
                    text-transform: uppercase;
                }
                &:hover {
                    border-color: $color-brand-black;
                    a {
                        color: $color-brand-black;
                    }
                }
            }
        }
        @media(max-width: 1025px) {
            padding: 40px 0;
            .post-container {
                &:nth-child(3n+3) {
                    padding-right: 33px;
                }
                &:nth-child(2n+2) {
                    padding-right: 0;
                }
            }
        }
        @media(max-width: 769px) {
            .post-container {
                padding-right: 0 !important;
                .post-image {
                    display: none;
                }
                .responsive-post-image {
                    display: block;
                }
            }
        }
    }
}

/****** Single Blog Post ******/
#blog-post {
    background-color: $color-brand-white;
    padding: 60px 100px;
    text-align: center;
    h1 {
        margin-bottom: 34px;
    }
    .date {
        @include BentonMedium();
        font-size: 14px;
        line-height: 14px;
        color: $color-brand-brown;
        letter-spacing: 1.17px;
        text-transform: uppercase;
        margin-bottom: 38px;
    }
    .hero-image {
        img {
            width: 100%;
            height: auto;
        }
    }
    .post-content {
        text-align: left;
        background-color: $color-white;
        padding: 68px 120px;
        h2 {
            margin-top: 0;
        }
    }
    .addtoany_content {
        .addtoany_header {
            float: left;
            padding-right: 10px;
            line-height: 28px;
            margin: 0;
            font-weight: bold;
        }
    }
    @media(max-width: 1025px) {
        padding: 20px 20px;
        .post-content {
            padding: 40px 80px;
        }
    }
    @media(max-width: 769px) {
        padding: 20px 0;
        .post-content {
            padding: 40px 20px;
        }
    }

}
